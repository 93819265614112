<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { leaseStatusObject } from '~/constants/LeaseStatus'
import type { ReportTableColumn } from '../types'
import Cell from './Cell.vue'

const props = defineProps<ReportTableColumn<'lease__Status'>>()

const statusColor = computed(() => {
  const statusColor = leaseStatusObject[props.value.value]?.color
  return twMerge([
    statusColor,
    '!block truncate whitespace-nowrap rounded-full text-[12px] border px-2.5 py-0.5 !bg-opacity-10 border-opacity-60 hover:!opacity:30',
  ])
})
</script>

<template>
  <Cell v-bind="props">
    <span
      class="flex items-center py-0 leading-3 underline-offset-2"
      :class="statusColor"
    >
      {{ title(value.value) }}
    </span>
  </Cell>
</template>
